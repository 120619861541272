import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/fetchMethods";
import { notify } from "../../utils/notify";

// ----------- redux thunk ----------
export const getFilesList: any = createAsyncThunk(
  "getFilesList",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, "/api/file_maanger/list");

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addFile: any = createAsyncThunk("addFile", async (body:any) => {
  try {
    
    // if(body.files.length === 0){
    //   notify("there is no file chosen", "error"); 
    // }
    // else{
      const result = await postRequest(body, "/api/file_maanger/store");
  
      if (!result.status) {
        throw new Error(result.msg);
      }
      notify("File added successfully", "success");
      return result;
    // }   
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});

export const deleteFile: any = createAsyncThunk("deleteFile", async (body) => {
  try {
    const result = await deleteRequest({}, `/api/file_maanger/delete/${body}`);

    if (!result.status) {
      throw new Error(result.msg);
    }
    notify("File deleted successfully", "success");
    return result;
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});

// ------------ initial state -----------
export interface FilesState {
  filesList: any;
}

const initialState: FilesState = {
  filesList: {},
};

// ------------ reducers ---------------
export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: {
    [getFilesList.fulfilled]: (state: any, { payload }) => {
      state.filesList = payload;
    },
  },
});

export default filesSlice.reducer;
