import axios from "axios";
import Cookies from "js-cookie";

export const postRequest = async (body: any, endPoint: any) => {
  const expiration: any = Cookies.get("expiration");
  if (expiration && +expiration < Date.now()) {
    Cookies.remove("token_");
    Cookies.remove("username");
    Cookies.remove("abotable_id");
    Cookies.remove("expiration");
    window.location.href = "/";
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${endPoint}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          apiSecret: `${process.env.REACT_APP_API_SECRET}`,
          Authorization: "Bearer " + Cookies.get("token_")!,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err.response.data.msg);
  }
};

export const putRequest = async (body: any, endPoint: any) => {
  const expiration: any = Cookies.get("expiration");
  if (+expiration < Date.now()) {
    Cookies.remove("token_");
    Cookies.remove("username");
    Cookies.remove("expiration");
    Cookies.remove("abotable_id");

    window.location.href = "/";
  }

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}${endPoint}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          apiSecret: `${process.env.REACT_APP_API_SECRET}`,
          Authorization: "Bearer " + Cookies.get("token_")!,
        },
      }
    );

    return response.data;
  } catch (err: any) {
    throw new Error(err.response.data.msg);
  }
};

export const getRequest = async (params: any, endPoint: any,lang?:any) => {
  
  

  const expiration: any = Cookies.get("expiration");
  if (+expiration < Date.now()) {
    Cookies.remove("token_");
    Cookies.remove("username");
    Cookies.remove("expiration");
    Cookies.remove("abotable_id");

    window.location.href = "/";
  }

  const headers = {
    Authorization: "Bearer " + Cookies.get("token_")!,
    apiSecret: `${process.env.REACT_APP_API_SECRET}`,
    lang
  };

  try {
    const response = axios
      .get(`${process.env.REACT_APP_BASE_URL}${endPoint}`, { headers, params })
      .then((response) => response.data);

    return response;
  } catch (err: any) {
    throw new Error(err.response.data.msg);
  }
};

export const deleteRequest = async (params: any, endPoint: any) => {
  const expiration: any = Cookies.get("expiration");
  if (+expiration < Date.now()) {
    Cookies.remove("token_");
    Cookies.remove("username");
    Cookies.remove("expiration");
    Cookies.remove("abotable_id");

    window.location.href = "/";
  }

  const headers = {
    Authorization: "Bearer " + Cookies.get("token_")!,
    apiSecret: `${process.env.REACT_APP_API_SECRET}`,
  };

  const response = axios
    .delete(`${process.env.REACT_APP_BASE_URL}${endPoint}`, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response;
};
