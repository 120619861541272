import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postRequest } from "../../utils/fetchMethods";
import { notify } from "../../utils/notify";
import Cookies from "js-cookie";
import axios from "axios";

// ----------- redux thunk ----------
export const login: any = createAsyncThunk("login", async (body: any) => {
  function decodeToken(token: any) {
    // Assuming the token is a JWT (JSON Web Token)
    const payloadBase64 = token.split(".")[1];
    const decodedPayload = atob(payloadBase64);
    return JSON.parse(decodedPayload);
  }

  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/login`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          apiSecret: `${process.env.REACT_APP_API_SECRET}`,
          Authorization: "Bearer " + Cookies.get("token_")!,
        },
      }
    );

    if (!response.status) {
      throw new Error(response?.msg);
    }

    Cookies.set("token_", response.data.user.api_token);
    Cookies.set("username", response.data.user.username);
    Cookies.set("abotable_id", response.data.user.id);
    Cookies.set(
      "expiration",
      `${decodeToken(response.data.user.api_token).exp * 1000}`
    );

    notify("Logged in successfully", "success");
    return response.data;
  } catch (error: any) {
    notify(error.response.data.msg, "error");
    throw new Error(error);
  }
});

export const resetPassword: any = createAsyncThunk(
  "resetPassword",
  async (body: any) => {
    try {
      const result = await postRequest(body, "/api/setPassword");

      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const sendEmailVerification: any = createAsyncThunk(
  "sendEmailVerification",
  async (body: any) => {
    try {
      const result = await postRequest(body, "/api/forgetPassword");

      if (!result.status) {
        throw new Error(result?.msg);
      }

      console.log(result);

      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

// ------------ initial state -----------
export interface LoginState {
  user: any;
  loginProcess: "signIn" | "forgetPassword" | "resetPassword";
  verification_code: number | string;
}

const initialState: LoginState = {
  user: {},
  loginProcess: "signIn",
  verification_code: "",
};

// ------------ reducers ---------------
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginProcess: (state, action) => {
      state.loginProcess = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setVerificationCode: (state, action) => {
      state.verification_code = action.payload;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state: any, { payload }) => {
      state.user = payload.user;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginProcess, setUser, setVerificationCode } =
  loginSlice.actions;

export default loginSlice.reducer;
