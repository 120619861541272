import Quill from "quill";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const Align = Quill.import("formats/align");
Align.whitelist = ["left", "center", "right"]; // Allow only left, center, and right alignment

Quill.register(Align, true);

const TextEditor = ({
  placeholder = "",
  formik,
  id,
  height = "100px",
  initialValue,
}: any) => {
  const [editorValue, setEditorValue] = useState("");
  const modules = {
    toolbar: [
      ["italic", "bold", "underline"],
      [{ align: [] }],
      ["link", "image", "video", "file"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = ["italic", "bold", "underline", "align", "link", "image"];

  //   ----------- functions -------------

  // ------------- side effects -------------
  useEffect(() => {
    formik?.setValues({ ...formik.values, [id]: editorValue });
  }, [editorValue]);

  useEffect(() => {
    if (initialValue) {
      setEditorValue(initialValue);
    }
  }, [initialValue]);

  return (
    <>
      <ReactQuill
        theme="snow"
        id={id}
        value={editorValue}
        onChange={setEditorValue}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{ height: height }}
      />
    </>
  );
};

export default TextEditor;
