import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/fetchMethods";
import { notify } from "../../utils/notify";

// ----------- redux thunk ----------
export const getSchoolList: any = createAsyncThunk(
  "getSchoolList",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, "/api/school/list");

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const getSchoolDetails: any = createAsyncThunk(
  "getSchoolDetails",
  async (params: any = {}) => {
    try {
      const result = await getRequest({}, `/api/school/show/${params}`);
      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addSchool: any = createAsyncThunk("addSchool", async (body) => {
  try {
    const result = await postRequest(body, "/api/school/store");

    if (!result.status) {
      throw new Error(result.msg);
    }
    notify("School created successfully", "success");
    return result;
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});

export const editSchool: any = createAsyncThunk(
  "editSchool",
  async (body: any) => {
    try {
      const result = await postRequest(
        body?.data,
        `/api/school/update/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }
      notify("School updated successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteAssignSchool: any = createAsyncThunk(
  "deleteAssignSchool",
  async (body) => {
    try {
      const result = await postRequest(body, `/api/assignsSchools/delete`);

      notify("School Assignment deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteSchool: any = createAsyncThunk(
  "deleteSchool",
  async (body) => {
    try {
      const result = await deleteRequest(body, `/api/school/delete/${body}`);

      notify("School deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const setSchoolStatus: any = createAsyncThunk(
  "setSchoolStatus",
  async (body) => {
    try {
      const result = await putRequest(body, `/api/school/status/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const assignSubjectToSchool: any = createAsyncThunk(
  "assignSubjectToSchool",
  async (body: any) => {
    try {
      const result = await postRequest(
        body?.data,
        `/api/school/assign_subject/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      // notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const assignTeacherToClass: any = createAsyncThunk(
  "assignTeacherToClass",
  async (body: any) => {
    try {
      const result = await postRequest(body, `/api/subject/assignTeacher`);

      if (!result.status) {
        throw new Error(result.msg);
      }
      notify("Employee added successfully","success")
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const assignToStudent: any = createAsyncThunk(
  "assignToStudent",
  async (body: any) => {
    try {
      const result = await postRequest(body, `/api/assigns/store`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteAssigning: any = createAsyncThunk(
  "deleteAssigning",
  async (body: any) => {
    try {
      const result = await deleteRequest(body, `/api/assigns/delete/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Assign deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

// ------------ initial state -----------
export interface SchoolState {
  schoolList: any;
  schoolDetails: any;
  stepTaps: number;
  schoolID: any;
  schoolTap:
    | "Overview"
    | "Subjects"
    | "Grades & Classes"
    | "School Employees"
    | "Students";
}

const initialState: SchoolState = {
  schoolList: {},
  schoolDetails: {},
  stepTaps: 0,
  schoolID: null,
  schoolTap: "Overview",
};

// ------------ reducers ---------------
export const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    nextStep: (state: any) => {
      state.stepTaps++;
    },
    prevStep: (state: any) => {
      state.stepTaps--;
    },
    resetSteps: (state: any) => {
      state.stepTaps = 0;
    },
    setSchoolID: (state: any, { payload }) => {
      state.schoolID = payload;
    },
    setSchoolTap: (state: any, { payload }) => {
      state.schoolTap = payload;
    },
  },
  extraReducers: {
    [getSchoolList.fulfilled]: (state: any, { payload }) => {
      state.schoolList = payload;
    },
    [getSchoolDetails.fulfilled]: (state: any, { payload }) => {
      state.schoolDetails = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { nextStep, resetSteps, prevStep, setSchoolID, setSchoolTap } =
  schoolSlice.actions;

export default schoolSlice.reducer;
