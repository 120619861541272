import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/fetchMethods";
import { notify } from "../../utils/notify";

// ----------- redux thunk ----------
export const getQuestionList: any = createAsyncThunk(
  "getQuestionList",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, "/api/questions/list");

      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const exportQuestionToEdit: any = createAsyncThunk(
  "exportQuestionToEdit",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, "/questions/export");

      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const uploadQuestions: any = createAsyncThunk(
  "uploadQuestions",
  async (body) => {
    try {
      const result = await postRequest(body, "/api/questions/fileImport");

      notify("File uploaded successfully", "success");
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const uploadEditedQuestions: any = createAsyncThunk(
  "uploadEditedQuestions",
  async (body) => {
    try {
      const result = await postRequest(body, "/api/questions/fileImportUpdate");

      notify("Questions updated successfully", "success");
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const deleteQuestion: any = createAsyncThunk(
  "deleteQuestion",
  async (body) => {
    try {
      const result = await postRequest(body, `/api/questions/delete`);

      notify("Question deleted successfully", "success");
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const getQuestionDetails: any = createAsyncThunk(
  "getQuestionDetails",
  async (params: any = {}) => {
    try {
      const result = await getRequest({}, `/api/questions/show/${params}`);

      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

// ------------ initial state -----------
export interface questionState {
  questionList: any;
}

const initialState: questionState = {
  questionList: {},
};

// ------------ reducers ---------------
export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: {
    [getQuestionList.fulfilled]: (state: any, { payload }) => {
      state.questionList = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = questionSlice.actions;

export default questionSlice.reducer;
