import React, { Suspense, lazy, useEffect, useState } from "react";
import TopBarProgress from "./components/shared/TopBarProgress";
import { Route, Routes } from "react-router-dom";
import { DashboardLayout } from "./layout/DashboardLayout";
import AuthGuard from "./auth/AuthGuard";
import { ToastContainer } from "react-toastify";
import "./config/i18next";
import "react-toastify/dist/ReactToastify.css";
import "reactflow/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { getPermissions } from "./redux/reducers/permissionReducer";
import Cookies from "js-cookie";
import NotFound from "./pages/NotFound/NotFound";
import { useTranslation } from "react-i18next";
import EditQuiz from "./components/QuizDetails/EditQuiz";

const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));

const Login = lazy(() => import("./pages/login/Login"));
const SelectSchool = lazy(() => import("./pages/login/SelectSchool"));

const Students = lazy(() => import("./pages/user/Students"));
const StudentDetails = lazy(() => import("./pages/user/StudentDetails"));
const AddStudents = lazy(() => import("./pages/user/AddStudents"));
const EditStudent = lazy(() => import("./pages/user/EditStudent"));

const Grade = lazy(() => import("./pages/school/Grade"));
const AddGrade = lazy(() => import("./pages/school/AddGrade"));
const EditGrade = lazy(() => import("./pages/school/EditGrade"));
const Classes = lazy(() => import("./pages/school/Classes"));

const Employee = lazy(() => import("./pages/user/Employee"));
const AddEmployee = lazy(() => import("./pages/user/AddEmployee"));
const EditEmployee = lazy(() => import("./pages/user/EditEmployee"));
const EmployeeDetails = lazy(() => import("./pages/user/EmployeeDetails"));

const Roles = lazy(() => import("./pages/school/Roles"));
const AddRole = lazy(() => import("./pages/school/AddRole"));
const EditRole = lazy(() => import("./pages/school/EditRole"));

const Subjects = lazy(() => import("./pages/subjects/Subjects"));
const SingleSubject = lazy(() => import("./pages/subjects/SingleSubject"));
const AddSubject: any = lazy(() => import("./pages/subjects/AddSubject"));
const EditSubject = lazy(() => import("./pages/subjects/EditSubject"));
const QuizDetalis = lazy(() => import("./components/QuizDetails/QuizDetails"));
const QuizDetails = lazy(
  () => import("./components/subjectDetails/SingleQuiz")
);
const AddWorksheet = lazy(
  () => import("./components/AddWorksheet/AddWorksheet")
);
const EditWorksheet = lazy(
  () => import("./components/AddWorksheet/EditWorksheet")
);
const AddGame = lazy(() => import("././components/addGame/AddGame"));
const EditGame = lazy(() => import("././components/addGame/EditGame"));
const AddContent = lazy(
  () => import("././components/subjectDetails/AddContent")
);
const EditContent = lazy(
  () => import("././components/subjectDetails/EditContent")
);
const SubStudentDetails = lazy(
  () => import("././components/subjectDetails/SubStudentDetails")
);
const QuestionBank = lazy(() => import("./pages/subjects/QuestionBank"));
const AddQuestion = lazy(() => import("./pages/subjects/AddQuestion"));

const Report = lazy(() => import("./pages/report/Reports"));

const Schools = lazy(() => import("./pages/school/Schools"));
const SchoolDetails = lazy(() => import("./pages/school/SchoolDetails"));
const AddSchool = lazy(() => import("./pages/school/AddSchool"));
const AddSchoolGrade = lazy(() => import("./pages/school/AddSchoolGrade"));
const AddSchoolEmployee = lazy(
  () => import("./pages/school/AddSchoolEmployee")
);
const AddSchoolStudent = lazy(() => import("./pages/school/AddSchoolStudent"));
const EditSchool = lazy(() => import("./pages/school/EditSchool"));
const SingleClasses = lazy(() => import("./pages/school/SingleClasses"));
const SchoolSubject = lazy(() => import("./pages/school/SchoolSubject"));
const Scorm = lazy(() => import("./components/subjectDetails/Scorm"));
const GameView = lazy(() => import("./components/addGame/GameView"));
const SheetView = lazy(() => import("./components/AddWorksheet/WorksheetView"));

const Tickets = lazy(() => import("./pages/ticketing/Tickets"));

const FileManagement = lazy(() => import("./pages/user/FileManagment"));

function App() {
  const dispatch = useDispatch()
  const userId = Cookies.get("abotable_id");
  const loginState = useSelector((state:any)=>state.login.user)  
  useEffect(()=>{
    if(userId){
      dispatch(getPermissions({id:userId}))      
    }    
  },[userId,loginState.id])
  const permissionState = useSelector((state: RootState) => state.permissions)
  const permissionLoading = useSelector((state: RootState) => state.permissions.loading)

  // console.log(permissionLoading,"loading"); 
  
  // quistions prermissions
  const viewQuistions = permissionState?.permissions?.questions?.find((permission:any) => permission['view-questions'] === "1")
  const addQuistions = permissionState?.permissions?.questions?.find((permission:any) => permission['add-questions'] === "1")


  // schools permissions

  const permissionsSchools = permissionState?.permissions?.schools;

  const editSchools = permissionsSchools?.find((permission:any) => permission['edit-schools'] === "1")
  const deleteSchools = permissionsSchools?.find((permission:any) => permission['delete-schools'] === "1")
  const addSchools = permissionsSchools?.find((permission: any) => permission["add-schools"] === "1")
  const viewSchools = permissionsSchools?.find((permission: any) => permission["view-schools"] === "1")
  const activationSchools = permissionsSchools?.find((permission: any) => permission["activation-schools"] === "1")

  // subjects permissions

  const permissionsSubjects = permissionState?.permissions?.subjects;

  const editSubjects = permissionsSubjects?.find((permission:any) => permission['edit-subjects'] === "1")
  const deleteSubjects = permissionsSubjects?.find((permission:any) => permission['delete-subjects'] === "1")
  const addSubjects = permissionsSubjects?.find((permission: any) => permission["add-subjects"] === "1")
  const viewSubjects = permissionsSubjects?.find((permission: any) => permission["view-subjects"] === "1")
  const activationSubjects = permissionsSubjects?.find((permission: any) => permission["activation-subjects"] === "1")

  // users permissions

  const permissionsUsers = permissionState?.permissions?.users;

  const editUsers = permissionsUsers?.find((permission:any) => permission['edit-users'] === "1")
  const deleteUsers = permissionsUsers?.find((permission:any) => permission['delete-users'] === "1")
  const addUsers = permissionsUsers?.find((permission: any) => permission["add-users"] === "1")
  const viewUsers = permissionsUsers?.find((permission: any) => permission["view-users"] === "1")
  const activationUsers = permissionsUsers?.find((permission: any) => permission["activation-users"] === "1")
  const exportUsers = permissionsUsers?.find((permission: any) => permission["export-users"] === "1")

  // grades permissions

  const permissionsGrades = permissionState?.permissions?.grades;

  const editGrades = permissionsGrades?.find((permission:any) => permission['edit-grades'] === "1")
  const deleteGrades = permissionsGrades?.find((permission:any) => permission['delete-grades'] === "1")
  const addGrades = permissionsGrades?.find((permission: any) => permission["add-grades"] === "1")
  const viewGrades = permissionsGrades?.find((permission: any) => permission["view-grades"] === "1")
  const activationGrades = permissionsGrades?.find((permission: any) => permission["activation-grades"] === "1")
  // students permissions

  const permissionsstudents = permissionState?.permissions?.students;

  const editstudents = permissionsstudents?.find((permission:any) => permission['edit-students'] === "1")
  const deletestudents = permissionsstudents?.find((permission:any) => permission['delete-students'] === "1")
  const addstudents = permissionsstudents?.find((permission: any) => permission["add-students"] === "1")
  const viewstudents = permissionsstudents?.find((permission: any) => permission["view-students"] === "1")
  const activationstudents = permissionsstudents?.find((permission: any) => permission["activation-students"] === "1")

  

  return (
    <div className="App bg-veryLightGray">
      <ToastContainer />
      <Suspense fallback={<TopBarProgress />}>
        <Routes>
          {/* auth routes  */}
          <Route index element={<Login />} />
          <Route path="select-school" element={<SelectSchool />} />
          <Route path="subjects/scorm/:scormId/:content" element={<Scorm />} />
          <Route path="subjects/GameView/:game_id" element={<GameView />} />
          <Route path="subjects/SheetView/:sheet_id" element={<SheetView />} />
          <Route
            path="/"
            element={
              <DashboardLayout>
                <AuthGuard />
              </DashboardLayout>
            }
          >
            {/* dashboard routes  */}
            <Route path="dashboard" element={<Dashboard />} />

            {/* school routes  */}
            {
              viewSchools &&
              <Route path="school/profile" element={<Schools />} />
            }
            {
              addSchools &&
              <Route path="school/profile/add-school" element={<AddSchool />} />
            }
            {
              editSchools &&
            <Route path="school/profile/edit/:id" element={<EditSchool />} />
            }
            {
              viewSchools &&
            <Route path="school/profile/:id" element={<SchoolDetails />} />
            }
            {
              addGrades &&
            <Route path="school/add-grade/:id" element={<AddSchoolGrade />} />
            }
            {
              editGrades &&
            <Route path="school/edit/:id" element={<EditGrade />} />
            }
            
            <Route
              path="school/add-employee/:id"
              element={<AddSchoolEmployee />}
            />
            {
              addstudents &&
              <Route
                path="school/add-student/:id"
                element={<AddSchoolStudent />}
              />
            }
            {
              viewGrades &&
            <Route path="school/grade" element={<Grade />} />
            }
            {
              addGrades &&
            <Route path="school/grade/add-grade" element={<AddGrade />} />
            }
            {
              editGrades &&
            <Route path="school/grade/edit/:id" element={<EditGrade />} />
            }
            {
              viewGrades &&
            <Route path="school/grade/:id" element={<Classes />} />
            }

            <Route
              path="school/grade/classes/:id"
              element={<SingleClasses />}
            />
            {
              viewSubjects &&
            <Route path="school/subject/:id" element={<SchoolSubject />} />
            }

            {
                (permissionState?.permissions?.roles?.find((permission:any) => permission['view-roles'] === "1")) &&
                <Route path="roles" element={<Roles />} />
            }
            {
                (permissionState?.permissions?.roles?.find((permission:any) => permission['add-roles'] === "1")) &&
                <Route path="roles/add-role" element={<AddRole />} />
            }
            {
                (permissionState?.permissions?.roles?.find((permission:any) => permission['edit-roles'] === "1")) &&
                <Route path="roles/edit/:id" element={<EditRole />} />
            }

            {/* subjects routes  */}

            {
              viewSubjects &&
            <Route path="subjects/list" element={<Subjects />} />
            }
            {
              addSubjects &&
            <Route path="subjects/add-subject" element={<AddSubject />} />
            }
            {
              editSubjects &&
            <Route path="subjects/edit/:id" element={<EditSubject />} />
            }
            {
              viewSubjects &&
            <Route path="subjects/:id" element={<SingleSubject />} />
            }
            
            {
              viewQuistions &&
              <Route path="question-bank" element={<QuestionBank />} />
            }
            {
            addQuistions &&
            <Route
              path="question-bank/add-question"
              element={<AddQuestion />}
            />
            }
            
            <Route
              path="subjects/add-worksheet/:subject_id"
              element={<AddWorksheet />}
            />
            <Route
              path="subjects/edit-worksheet/:subject_id/:sheet_id"
              element={<EditWorksheet />}
            />
            <Route path="subject/add-game/:subject_id" element={<AddGame />} />
            <Route
              path="subject/edit-game/:subject_id/:game_id"
              element={<EditGame />}
            />
            <Route path={`subjects/add-content/:id`} element={<AddContent />} />
            <Route
              path={`subjects/Edit-content/:id/:id`}
              element={<EditContent />}
            />
            {
              viewstudents &&
              <Route
                path="subjects/student-details"
                element={<SubStudentDetails />}
              />
            }

            <Route path="subjects/quiz/:id" element={<QuizDetails />} />
            <Route
              path="subjects/:subjectId/add-quiz"
              element={<QuizDetalis />}
            />
            <Route
              path="subjects/:subjectId/edit-quiz/:quizId"
              element={<EditQuiz />}
            />

            {/* report routes  */}
            <Route path="report/list" element={<Report />} />

            {/* file management routes  */}
{            
            permissionState &&
            (permissionState?.permissions?.file_managers?.find((permission:any) => permission['view-file_managers'] === "1")) &&
            <Route path="file-management" element={<FileManagement />} />
}

            {/* user routes  */}

            {
              viewUsers &&
            <Route path="user/employee" element={<Employee />} />
            }
            {
              addUsers &&
              <Route
                path="user/employee/add-employee"
                element={<AddEmployee />}
              />
            }
            {
              viewUsers &&
              <Route
                path="user/employee/view/:id"
                element={<EmployeeDetails />}
              />
            }
            {
              editUsers &&
            <Route path="user/employee/edit/:id" element={<EditEmployee />} />
            }
            {
              viewstudents &&
            <Route path="user/student" element={<Students />} />
            }
            {
              addstudents &&
            <Route path="user/student/add-student" element={<AddStudents />} />
            }
            {
              viewstudents &&
            <Route path="user/student/view/:id" element={<StudentDetails />} />
            }
            {
              editstudents &&
            <Route path="user/student/edit/:id" element={<EditStudent />} />
            }

            {/* tickets routes  */}
            <Route path="tickets/list" element={<Tickets />} />
            {
              permissionState && !permissionLoading &&
              <Route path="*" element={<NotFound/>}/>
            }
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
